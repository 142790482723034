import React from "react";

const SectionCaption = ({ caption }) => {
  return (
    <>
      <h3 className="section__caption">{caption}</h3>
    </>
  );
};

export { SectionCaption };
