import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { SectionTitle } from "../SectionTitle";
import { TestifyCard } from "../TestifyCard";
import { Navigation, Pagination } from "swiper";
const testifyArray = [
  {
    src: "user-testify",
    authorThumbnail: "hari-thumb.png",
    authorName: "Mr. Hari Sudhakaran",
    authorCompany: "Secretary, Nakul Raj Co-op Housing Society",
    authorReview:
      "Immensely grateful to Raj Builders for their commitment to transparency and integrity. Throughout the entire process, our society felt well-informed and confident in our decisions, thanks to their clear communication and genuine advice.",
  },
];
const Testify = () => {
  return (
    <>
      <section className="testify__wrap section__row">
        <div className="main__container">
          <SectionTitle title="Our Happy Customers" />
          <div className="testify__container">
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              navigation={true}
              pagination={{
                type: "fraction",
              }}
              modules={[Navigation, Pagination]}
              className="testifyMySwiper"
            >
              {testifyArray?.map((item, index) => (
                <SwiperSlide key={index}>
                  <TestifyCard
                    src={item.src}
                    authorThumbnail={item.authorThumbnail}
                    authorName={item.authorName}
                    authorCompany={item.authorCompany}
                    authorReview={item.authorReview}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export { Testify };
