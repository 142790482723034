import React from "react";

const OurProjectHeader = ({ title, count, lbl = "properties" }) => {
  return (
    <>
      <div className="ourproj__header">
        <h2 className="ourproj__header-title">
          <span>{title}</span>
        </h2>
        <div className="ourproj__header-counter">
          <span>{count}</span> <em>{lbl}</em>
        </div>
      </div>
    </>
  );
};

export { OurProjectHeader };
