import React, { useRef, useState } from "react";
import { SectionTitle } from "../SectionTitle";
import { SectionCaption } from "../SectionCaption";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";
import { Accordion } from "../../ReusableComponents/Accordion";
import AccordionChild from "../../ReusableComponents/Accordion/AccordionChild";
const OurServices = () => {
  const FAQs = [
    {
      title: "COMMERCIAL",
      isActive: true,
      thumbnail: "commercial.jpg",
      caption:
        "Discover a diverse range of commercial properties to meet your business needs. Whether you're looking for office spaces, retail outlets, or industrial facilities. Explore spaces that offer the perfect environment for your business to thrive and make a lasting impact.",
    },
    {
      title: "RESIDENTIAL",
      isActive: false,
      thumbnail: "residential.jpg",
      caption:
        "Find your dream home among our collection of exquisite residential properties. From cozy apartments to luxurious villas, our listings cater to various preferences and lifestyles. Explore well-designed living spaces ensuring you and your family enjoy the perfect sanctuary.",
    },
    {
      title: "Community",
      isActive: false,
      thumbnail: "community.jpg",
      caption:
        "Explore our portfolio of transformative community projects that contribute to the betterment of society. These projects range from sustainable housing developments to recreational spaces that foster a sense of togetherness.",
    },
  ];
  const itemsRef = useRef([]);

  const [faqs, setFaqs] = useState(FAQs);
  const [activeIndex, setActiveIndex] = useState(faqs[0].isActive);
  const [isUpdateAccordion, setIsUpdateAccordion] = useState(false);

  const updateActiveIndex = (index, value) => {
    if (!value) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
      setTimeout(() => {
        let scrollIndex = index < 2 ? 0 : index - 2;
        if (scrollIndex > 0) {
          itemsRef.current[scrollIndex]?.scrollIntoView({
            behavior: "auto",
            block: "nearest",
            inline: "start",
          });
        }
      }, 100);
    }
    setIsUpdateAccordion(true);
  };
  const updateFaqs = (data) => setFaqs(data);
  return (
    <>
      <section className="ourService__wrap section__row">
        <div className="main__container">
          <SectionTitle title="Our Services" />
          <SectionCaption caption="We offer a variety of services. Explore our services and contact us to discuss your needs and ideas. We are here to help you make your dream a reality." />
        </div>
        <div className="main__container">
          <div className="ourService__accordion">
            <Accordion
              extraCls="vertical"
              data={faqs}
              activeIndex={activeIndex}
              updateData={updateFaqs}
              isUpdateAccordion={isUpdateAccordion}
              accordionChild={faqs.map((faq, index) => (
                <AccordionChild
                  innerRef={(el) => (itemsRef.current[index] = el)}
                  key={index}
                  accordionConfig={{
                    showAccordRHS: false,
                    showAccordArrow: false,
                  }}
                  headComponentLHS={
                    <>
                      <div className="ourService__card-acordHead">
                        {" "}
                        <div className="ourService__card-counter">{`0${
                          index + 1
                        }`}</div>
                        <div className="ourService__card-title">
                          {faq.title}
                        </div>
                      </div>
                    </>
                  }
                  bodyComponent={
                    <>
                      <div key={index} className="ourService__card">
                        <div className="ourService__card-grid">
                          <div className="ourService__card-gridLHS">
                            <div className="ourService__card-thumb">
                              <img
                                src={`${`${IMAGE_BASE_URL}/our-service`}/${
                                  faq.thumbnail
                                }?v=${IMAGE_VERSION}`}
                                alt={"cement-thumb"}
                                loading="lazy"
                              />
                            </div>
                          </div>
                          <div className="ourService__card-gridRHS">
                            <div className="ourService__card-caption">
                              {faq.caption}
                            </div>
                            <div className="ourService__card-cta"></div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  index={index}
                  isActive={faq.isActive}
                  updateActiveIndex={updateActiveIndex}
                />
              ))}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export { OurServices };
