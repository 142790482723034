import React from "react";
import { socialArr } from "../../Common/config";

const SocialMedia = () => {
  return (
    <>
      <div className="sitemap__social">
        {socialArr.map((item, index) => (
          <a
            key={index}
            href={item.link}
            target={item.target}
            rel="noopener noreferrer"
          >
            <i className={`rb-${item.iconName}`}></i>
          </a>
        ))}
      </div>
    </>
  );
};

export default SocialMedia;
