import React, { useEffect, useMemo, useRef, useState } from "react";
import { InputField } from "../InputField";
import { InputFieldValidation } from "../InputFieldValidation";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const Dropdown = ({
  parentExtraCls,
  dropdownLbl,
  dropdownList,
  dropdownValue,
  handleClick,
  handleBlur = () => {},
  field = "",
  hasSearch = false,
}) => {
  const [searchText, setSearchText] = useState("");
  const [isActive, setIsActive] = useState(true);

  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setIsActive(true));

  const filteredList = useMemo(() => {
    if (!hasSearch) return dropdownList;
    if (!searchText) {
      return dropdownList;
    } else {
      return dropdownList.filter((list) =>
        list.display.toLowerCase().includes(searchText.toLowerCase())
      );
    }
  }, [dropdownList, searchText, hasSearch]);

  function handleSearch(e) {
    setSearchText(e.target.value);
  }

  useEffect(() => {
    if (!isActive && field) {
      handleBlur(field);
    }
  }, [isActive, handleBlur, field]);

  return (
    <>
      <div
        className={`dropdown ${parentExtraCls} ${!isActive ? "active" : ""}`}
        ref={dropdownRef}
      >
        {dropdownLbl ? <div className="dropdown__lbl">{dropdownLbl}</div> : ""}
        <div className="dropdown__box">
          <div
            className="dropdown__value"
            onClick={() => {
              setIsActive(!isActive);
            }}
          >
            {dropdownValue}
            <div className="value__icon">
              {!isActive ? (
                <i className="rb-chevron-up"></i>
              ) : (
                <i className="rb-chevron-down"></i>
              )}
            </div>
          </div>
          {!isActive ? (
            <div className="dropdown__list">
              {hasSearch && (
                <div className="dropdown__search">
                  <InputField
                    inputValidate={filteredList?.length === 0}
                    inputID={"propertyFilter+"}
                    inputType={"text"}
                    placeholderLabel={"Search using keywords"}
                    inputText={searchText}
                    handleInput={handleSearch}
                    extraAttri={{ inputMode: "search" }}
                    components={
                      <>
                        <InputFieldValidation validationText="not matched" />
                      </>
                    }
                  />
                </div>
              )}
              <ul>
                {filteredList.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      handleClick(field, item.value);
                      setIsActive(!isActive);
                    }}
                    className={dropdownValue === item?.value ? "active" : ""}
                  >
                    <span>{item?.display}</span>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export { Dropdown };
