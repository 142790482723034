import React, { useEffect, useRef, useState } from "react";

const InputField = ({
  parentExtraCls,
  textAreaField = false,
  inputLbl,
  inputID,
  inputType,
  placeholderLabel = false,
  isDisabled = false,
  inputText,
  handleInput,
  extraAttri = {},
  components,
  extraCls,
  inputValidate,
  onBlur = () => {},
  pickOnce = false,
  focused = () => {},
}) => {
  const parentDiv = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (inputText) {
      if (!parentDiv.current.classList.contains("picked")) {
        setIsFocused(true);
        focused(true);
      }
    } else {
      if (!inputText && !pickOnce) {
        setIsFocused(false);
        focused(false);
      }
    }
  }, [inputText]);

  function handleFocus() {
    if (!parentDiv.current.classList.contains("picked")) {
      setIsFocused(true);
      focused(true);
    }
  }

  function handleBlur(e) {
    if (!inputText && !pickOnce) {
      setIsFocused(false);
      focused(false);
    }
    onBlur(e);
  }

  return (
    <>
      <div
        ref={parentDiv}
        className={`input ${parentExtraCls ? parentExtraCls : ""} ${
          inputValidate ? "invalid" : ""
        } ${isFocused ? "picked" : ""}`}
      >
        {inputLbl ? (
          <label className="lbl" htmlFor={inputID}>
            {inputLbl}
          </label>
        ) : (
          ""
        )}
        <div className="input__field">
          {!textAreaField ? (
            <input
              autoComplete="off"
              name={inputID}
              type={inputType}
              id={inputID}
              placeholder={placeholderLabel}
              disabled={isDisabled}
              value={inputText}
              onChange={handleInput}
              onFocus={handleFocus}
              onBlur={handleBlur}
              {...extraAttri}
            />
          ) : (
            <textarea
              name={inputID}
              id={inputID}
              placeholder={placeholderLabel}
              cols="30"
              rows="10"
              onChange={handleInput}
              onFocus={handleFocus}
              onBlur={handleBlur}
            ></textarea>
          )}

          <div className="form__input-border"></div>
        </div>
        {inputValidate ? components : ""}
      </div>
    </>
  );
};

export { InputField };
