import React from "react";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";
import { Anchor } from "../Anchor";
import { isMobile } from "../../Common/utils";
const OurProjectCard = ({
  title,
  location,
  status,
  types,
  srcThumbnail,
  thumbnail,
  tag,
  cost,
  costSlug,
  redirectURL,
}) => {
  return (
    <>
      <div className="ourproj__card">
        <div className="ourproj__card-top">
          {tag && (
            <div className="ourproj__card-tag">
              {tag.map((item, index) => (
                <span key={index}>{item}</span>
              ))}
            </div>
          )}
          <div className="ourproj__card-thumb">
            <img
              className="ourproj__card-thumb-img"
              src={`${
                srcThumbnail
                  ? `${IMAGE_BASE_URL}/${srcThumbnail}`
                  : `${IMAGE_BASE_URL}`
              }/${thumbnail ? thumbnail : thumbnail}?v=${IMAGE_VERSION}`}
              alt={title}
              loading="lazy"
            />
          </div>
          <div className="ourproj__card-bar">
            <span>{title}</span>
            <span>{location}</span>
          </div>
        </div>
        <div className="ourproj__card-bot">
          <div className="ourproj__card-hover">
            <div className="ourproj__card-hover-top">
              <h2 className="ourproj__card-title">
                <span>{title}</span>
              </h2>
              <div className="ourproj__card-dtls">
                <span>{location}</span>
                {status && (
                  <>
                    | <span>{status}</span>
                  </>
                )}
              </div>
              <div className="ourproj__card-types">{types}</div>
            </div>
            <div className="ourproj__card-hover-bot">
              <div className="ourproj__card-price">
                {!isMobile() && (
                  <div className="ourproj__card-priceLBL">
                    <span> Price:</span>
                  </div>
                )}
                {cost} {costSlug || costSlug}
              </div>
              <div className="ourproj__card-cta">
                <Anchor
                  linkCls="btn btn__white btn__white"
                  linkText="Enquire now"
                  hyperLink={redirectURL}
                  linkTarget="_self"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { OurProjectCard };
