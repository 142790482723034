import React from "react";
import { SectionTitle } from "../SectionTitle";
import { SectionCaption } from "../SectionCaption";
import { OurWorkCard } from "../OurWorkCard";
import { Anchor } from "../Anchor";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid } from "swiper";

const ourWorkArr = [
  {
    projectSrc: "our-work",
    projectImg: "proj1-mubarak.jpg",

    projectName: "MUBARAK MANZIL",
    projectVenue: "Marol, Andheri East",
    projectTag: ["Ongoing", "Residential"],
    projectType: "1, 2, 3 BHK",
    projectCost: "₹1.3Cr Onwards",
    projectLink: "/",
  },
  {
    projectSrc: "our-work",
    projectImg: "proj2-jamali.jpg",
    projectName: "JAMALI",
    projectVenue: "Marol, Andheri East",
    projectTag: ["Ongoing", "Residential"],
    projectType: "Studio, 1, 2, 3 BHK",
    projectCost: "₹90L Onwards ",
    projectLink: "/",
  },
  {
    projectSrc: "our-work",
    projectImg: "proj3-nakul.jpg",
    projectName: "NAKUL RAJ",
    projectVenue: "Malad West",
    projectTag: ["Ongoing", "Commercial", "Residential"],
    projectType: "1, 2, 2.5 BHK",
    projectCost: "₹1.5Cr Onwards",
    projectLink: "/",
  },
];
const OurWork = () => {
  return (
    <>
      <section className="ourWork__wrap section__row">
        <div className="main__container">
          <SectionTitle title="Our Works" />
          <SectionCaption caption="Discover our exceptional real estate projects, featuring meticulously designed properties that redefine luxury living. With a focus on quality craftsmanship, innovative architecture, and desirable locations, we offer unparalleled residential and commercial spaces for the discerning buyer or investor." />
        </div>
        <div className="main__container">
          <div className="ourWork__swiper">
            <Swiper
              slidesPerView={1}
              simulateTouch={false}
              spaceBetween={10}
              grabCursor={true}
              loop={true}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                  centeredSlides: true,
                  loop: true,
                },
                "@0.75": {
                  slidesPerView: 1,
                  spaceBetween: 0,
                  grid: {
                    rows: 2,
                  },
                },
              }}
              modules={[Grid]}
              className="heroBannerMySwiper"
            >
              {ourWorkArr?.map((item, index) => (
                <SwiperSlide key={index}>
                  <OurWorkCard
                    key={index}
                    projectCounter={`0${index + 1}`}
                    projectSrc={item.projectSrc}
                    projectImg={item.projectImg}
                    projectName={item.projectName}
                    projectVenue={item.projectVenue}
                    projectTag={item.projectTag.map((item) => item)}
                    projectType={item.projectType}
                    projectCost={item.projectCost}
                    projectLink={item.projectLink}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="ourWork__cta">
            <Anchor
              linkCls="btn btn__link btn__link--white "
              iconCls=""
              linkText="View All Project"
              hyperLink="/our-work"
              linkTarget="_self"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export { OurWork };
