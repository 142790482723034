import React from "react";
import { isMobile } from "../../Common/utils";
import { Anchor } from "../Anchor";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";

const OurWorkCard = ({
  projectCounter,
  projectSrc,
  projectImg,
  projectName,
  projectVenue,
  projectTag,
  projectType,
  projectCost,
  projectLink,
}) => {
  return (
    <>
      <div className="ourWork__card">
        <div className="ourWork__grid">
          {!isMobile() && (
            <div className="ourWork__grid-item ourWork__grid-item--num">
              <div className="ourWork__details-counter">{projectCounter}</div>
            </div>
          )}
          <div className="ourWork__grid-item ourWork__grid-item--dtls">
            <div className="ourWork__details">
              <div className="ourWork__details-top">
                <div className="ourWork__details-name">{projectName}</div>
                <div className="ourWork__details-tag">
                  {projectTag.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </div>
              </div>
              <div className="ourWork__details-mid">
                <div className="ourWork__details-venue">{projectVenue}</div>
              </div>
              <div className="ourWork__details-bot">
                <div className="ourWork__details-type">{projectType}</div>
                <div className="ourWork__details-cost">{projectCost}</div>
              </div>
            </div>
          </div>
          <div className="ourWork__grid-item ourWork__grid-item--thumb">
            <div className="ourWork__details-thumbnail">
              <img
                src={`${
                  projectSrc
                    ? `${IMAGE_BASE_URL}/${projectSrc}`
                    : `${IMAGE_BASE_URL}`
                }/${
                  projectImg ? projectImg : "placeholder.jpg"
                }?v=${IMAGE_VERSION}`}
                alt={projectName}
                loading="lazy"
              />
            </div>
          </div>
          {!isMobile() && (
            <div className="ourWork__grid-item ourWork__grid-item--cta">
              <div className="ourWork__details-cta">
                <Anchor
                  linkCls="btn btn__icon noClick"
                  iconCls="rb-arrow"
                  linkText=""
                  hyperLink={`${projectLink}`}
                  linkTarget="_self"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { OurWorkCard };
