import React from "react";

const InputFieldValidation = ({ extraCls, validationText }) => {
  return (
    <>
      <div className={`errMsg ${extraCls ? extraCls : ""}`}>
        {validationText}
      </div>
    </>
  );
};

export { InputFieldValidation };
