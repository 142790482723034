import React from "react";

const SectionTitle = ({ title }) => {
  return (
    <>
      <h2 className="section__title">{title}</h2>
    </>
  );
};

export { SectionTitle };
