import React from "react";
import { SectionTitle } from "../SectionTitle";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";

const ourSpecializationArr = [
  {
    title: "Quality",
    caption:
      "Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Quality",
    caption:
      "Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    title: "Quality",
    caption:
      "Description: Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

const OurSpecialization = () => {
  return (
    <>
      <section className="ourSpecialization__wrap section__row">
        <div className="main__container">
          <SectionTitle title="Our Specialization" />
        </div>
        <div className="main__container">
          <div className="ourSpecialization__container">
            <div
              className="ourSpecialization__grid"
              data-watermark="Smart Construction Flexible Solution"
            >
              <div className="ourSpecialization__grid-LHS">
                <div className="ourSpecialization__grid-thumb">
                  <img
                    src={`${`${IMAGE_BASE_URL}/`}/${"cement-thumb.jpg"}?v=${IMAGE_VERSION}`}
                    alt={"cement-thumb"}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="ourSpecialization__grid-RHS">
                {/* <div className="ourSpecialization__grid-title">
                  <span>Cement</span>
                </div> */}
                <div className="ourSpecialization__grid-caption">
                  <p>
                    We don't choose a place for the project; we choose the right
                    investment for you.
                  </p>
                  <p>
                    When it comes to construction projects, the quality of the
                    materials used plays a crucial role in determining the
                    longevity and strength of the structure. That's why we take
                    pride in offering the best materials for all your
                    construction needs.
                  </p>
                  <p>
                    Every detail matters to us, and our dedication to using
                    top-tier materials is evident in the seamless blend of
                    luxury and functionality within our properties. With a focus
                    on excellence, Raj Builders sets a new standard for homes
                    that are not just spaces, but enduring legacies crafted from
                    the best materials the industry has to offer.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="ourSpecialization__list">
              <ul>
                {ourSpecializationArr.map((item, index) => (
                  <li key={index}>
                    <div className="ourSpecialization__box">
                      <h3 className="ourSpecialization__box-title">
                        {item.title}
                      </h3>
                      <div className="ourSpecialization__box-capiton">
                        {item.caption}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export { OurSpecialization };
