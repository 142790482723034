import React, { useEffect } from "react";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";

const ProjectCard = ({
  src,
  thumbnail,
  title,
  venue,
  posterImg,
  isVideo = true,
}) => {
  return (
    <>
      <div className="project__card-box">
        <div className="project__card-thumb">
          {isVideo ? (
            <>
              {/* <img
                className="posterThumbnail"
                src={`${`${IMAGE_BASE_URL}`}/${posterImg}?v=${IMAGE_VERSION}`}
                alt={title}
                loading="lazy"
              /> */}
              <iframe
                id="ik_player_iframe"
                width=""
                height=""
                src={`${thumbnail}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; loop; reload; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </>
          ) : (
            <img
              src={`${src ? `${IMAGE_BASE_URL}/${src}` : `${IMAGE_BASE_URL}`}/${
                thumbnail ? thumbnail : "placeholder.jpg"
              }?v=${IMAGE_VERSION}`}
              alt={title}
              loading="lazy"
            />
          )}
        </div>
        <div className="project__card-info">
          <h2 className="project__card-title">{title}</h2>
          <h3 className="project__card-venue">{venue}</h3>
        </div>
      </div>
    </>
  );
};

export { ProjectCard };
