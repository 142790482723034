import React from "react";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";

const TestifyCard = ({
  src,
  authorThumbnail,
  authorName,
  authorCompany,
  authorReview,
}) => {
  return (
    <>
      <div className="testify__card">
        <div className="testify__card-grid">
          <div className="testify__card-thumb">
            <img
              src={`${src ? `${IMAGE_BASE_URL}/${src}` : `${IMAGE_BASE_URL}`}/${
                authorThumbnail ? authorThumbnail : "placeholder.jpg"
              }?v=${IMAGE_VERSION}`}
              alt={authorName}
              loading="lazy"
            />
          </div>
          <div className="testify__card-details">
            <div className="testify__card-review">{authorReview}</div>
            <div className="testify__card-author">
              {authorName} {authorCompany ? `| ${authorCompany}` : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { TestifyCard };
